import { styled } from '@mui/material';
import React from 'react';
import { Stack } from '../Stack';
import { Typography } from '../Typography';

const StyledTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'single',
})<{ single?: boolean }>`
  flex: 1;
  align-items: center;
  text-align: start;
  :last-of-type {
    text-align: ${({ single }) => (single ? 'end' : 'start')};
  }
`;

export const StyledStackItems = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'single',
})<{ single?: boolean }>`
  padding: ${({ theme }) => `${theme.spacing(1)} ${theme.spacing(2)}`};
  border-bottom: ${({ single }) => (single ? '' : '1px solid')};
  border: ${({ single }) => (single ? '1px solid' : '')};
  border-color: ${({ theme }) => theme.palette.neutral[200]};
  border-radius: ${({ single, theme }) =>
    single ? theme.borderRadii.inner : ''};
  align-items: center;
  margin-bottom: ${({ single, theme }) => (single ? theme.spacing(2) : '')};
  &:last-child {
    margin: 0;
  }
  justify-content: center;
  width: 100%;
  flex-direction: row;
`;

interface SummaryCardFieldProps {
  readonly label?: string;
  readonly color?: string;
  readonly single?: boolean;
  readonly isValue?: boolean;
  readonly secondLabel?: string;
}

export function SummaryCardField({
  label,
  color,
  single = false,
  children,
}: React.PropsWithChildren<SummaryCardFieldProps>) {
  return (
    <StyledStackItems single={single}>
      <StyledTypography color={color} variant="captionStrong">
        {label}
      </StyledTypography>
      {children}
    </StyledStackItems>
  );
}
