import { ErrorBoundaryPage } from '@/components/ErrorBoundaryPage/ErrorBoundaryPage';
import { useAppLocation } from '@/hooks/customHooks/useAppLocation';
import React, { Suspense, useContext, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import {
  ClaimScoreLoader,
  Divider,
  ErrorBoundary,
  NavigationHistoryProvider,
  styled,
  useBreakpoints,
} from 'ui';
import { AuthContext } from '@context/AuthContext';
import { LayoutProvider } from '@context/LayoutContext';
import { UserOrgCaseProvider } from '@context/UserOrgCaseContext';
import { PATHS } from '@utils/paths';
import { SideNavbar } from './components/SideNavbar/SideNavbar';
import { MainTopNavbar } from './components/TopNavbar/MainTopNavbar';
import { SecondaryTopNavbar } from './components/TopNavbar/SecondaryTopNavbar';

const DashboardLayoutRoot = styled('div', {
  shouldForwardProp: (prop) => prop !== 'hasSidebar',
})<{ hasSidebar?: boolean }>`
  width: 100%;
  height: 100vh;
  overflow: auto;
  position: fixed;
`;

const StyledMain = styled('main', {
  shouldForwardProp: (prop) => prop !== 'shouldHideSecondNavbar',
})<{ shouldHideSecondNavBar: boolean }>`
  width: 100%;
  height: fit-content;
  max-width: 1200px;
  margin: 0 auto;
  padding: ${({ theme }) => `${theme.spacing(4)} ${theme.spacing(3)}`};
  padding-top: 88px;
  @media (min-width: ${({ theme }) => `${theme.breakpoints.values.lg}px`}) {
    padding-top: ${({ shouldHideSecondNavBar }) =>
      shouldHideSecondNavBar ? '88px' : '176px'};
  }
`;

const MainWrapper = styled('main')`
  @media (min-width: ${({ theme }) => `${theme.breakpoints.values.lg}px`}) {
    margin-left: 84px;
  }
`;

interface PrivateLayoutProps {
  readonly withSidebar?: boolean;
  readonly withNavbar?: boolean;
}

export function PrivateLayout({ withSidebar, withNavbar }: PrivateLayoutProps) {
  const { isXlUp } = useBreakpoints();
  const { isAuthenticated } = useContext(AuthContext);
  const { shouldShowOrgOrCase } = useAppLocation();

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleOpenSidebar = () => {
    setIsSidebarOpen(true);
  };

  const handleCloseSidebar = () => {
    setIsSidebarOpen(false);
  };

  const handleChangeSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  if (!isAuthenticated) {
    return <Navigate to={PATHS.PUBLIC.login} />;
  }

  return (
    <NavigationHistoryProvider>
      <LayoutProvider>
        <UserOrgCaseProvider>
          <div style={{ display: isXlUp ? 'flex' : 'block' }}>
            {withSidebar ? (
              <SideNavbar
                onClose={handleCloseSidebar}
                onMouseOver={handleOpenSidebar}
                open={isSidebarOpen}
              />
            ) : null}
            <DashboardLayoutRoot hasSidebar={withSidebar}>
              {withNavbar ? (
                <>
                  <MainTopNavbar toggleSidebar={handleChangeSidebar} />
                  <Divider noMargin orientation="horizontal" />
                  {shouldShowOrgOrCase ? <SecondaryTopNavbar /> : null}
                </>
              ) : null}
              <ErrorBoundary fallback={<ErrorBoundaryPage />}>
                <Suspense fallback={<ClaimScoreLoader />}>
                  <MainWrapper>
                    <StyledMain shouldHideSecondNavBar={!shouldShowOrgOrCase}>
                      <Outlet />
                    </StyledMain>
                  </MainWrapper>
                </Suspense>
              </ErrorBoundary>
            </DashboardLayoutRoot>
          </div>
        </UserOrgCaseProvider>
      </LayoutProvider>
    </NavigationHistoryProvider>
  );
}
