import React from 'react';
import { styled } from '../../utils/styled';
import { Divider } from '../Divider';
import { Stack } from '../Stack';
import { Typography } from '../Typography';

const addFlexStyle = {
  display: 'flex',
};

const labelStyle = {
  ...addFlexStyle,
  margin: 0,
  width: '96px',
};

const StyledDrawerItem = styled(Stack)`
  align-items: center;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing(3)};
`;
export interface DrawerItemProps {
  readonly label: string;
  readonly item: React.ReactNode;
}

export function DrawerItem({
  label,
  item,
}: DrawerItemProps): React.ReactElement {
  return (
    <>
      <StyledDrawerItem>
        <Typography color="text.secondary" sx={labelStyle} variant="caption">
          {label}
        </Typography>
        <Typography color="text.secondary" sx={addFlexStyle} variant="body">
          {item}
        </Typography>
      </StyledDrawerItem>
      <Divider noMargin />
    </>
  );
}
