import { styled } from '@mui/system';
import React from 'react';
import { ElementWithBadge } from '../ElementWithBadge';
import { Stack } from '../Stack';
import { Typography } from '../Typography';

export interface PersonaProps {
  readonly userName: string;
  readonly companyName: string;
}

const StyledStack = styled(Stack)`
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing(2)};
`;

export function Persona({ userName, companyName }: PersonaProps) {
  return (
    <StyledStack>
      <ElementWithBadge
        badgeColor="error"
        element="avatar"
        name={userName}
        size="large"
      />
      <Stack>
        <Typography sx={{ mb: (theme) => theme.spacing(0) }} variant="h4">
          {userName}
        </Typography>
        <Typography variant="body">{companyName}</Typography>
      </Stack>
    </StyledStack>
  );
}
