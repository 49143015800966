import React, { useContext, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import {
  Button,
  ElementWithBadge,
  LogoutIcon,
  MenuIcon,
  PopoverMenu,
  Stack,
  styled,
  TopNavbar,
  Typography,
  useBreakpoints,
} from 'ui';
import { ColorGreen200 } from 'ui/src/design-system/colors';
import { PageHeaderPortal } from '@components/PageHeaderTitle/PageHeaderTitle';
import { AuthContext } from '@context/AuthContext';

const StyledButton = styled(Button)`
  text-transform: lowercase;
`;

const StyledAvatarContainer = styled(Stack)`
  padding: ${({ theme }) => theme.spacing(3)};
  gap: ${({ theme }) => theme.spacing(2)};
  align-items: center;
  flex-direction: row;
`;

const StyledNavbar = styled(TopNavbar, {
  shouldForwardProp: (prop) =>
    ['hasSidebar', 'leftToolbar', 'rightToolbar'].includes(String(prop)),
})<{ hasSidebar?: boolean }>`
  box-shadow: 0px 0px 0px rgba(100, 116, 139, 0.16);
  width: ${({ theme, hasSidebar }) =>
    hasSidebar
      ? `calc(100% - ${theme.constants.navigationDrawerMinimizedWidth})`
      : undefined};
`;

interface MainTopNavbarProps {
  readonly toggleSidebar?: () => void;
}

const SIDE_MENU_BTN_LABEL = 'Open Side Navigation Drawer';

export function MainTopNavbar({ toggleSidebar }: MainTopNavbarProps) {
  const { isLgUp } = useBreakpoints();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { user, logout } = useContext(AuthContext);
  const [, setSearchParams] = useSearchParams();

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const anchorEl = useRef<HTMLDivElement>(null);

  const hasSideBar = useMemo(
    () => isLgUp && pathname !== '/',
    [isLgUp, pathname],
  );

  const shouldShowSideMenuButton = useMemo(
    () => !isLgUp && pathname !== '/',
    [isLgUp, pathname],
  );

  const getLeftToolbar = () => (
    <>
      {shouldShowSideMenuButton ? (
        <Button
          aria-label="Open Side Navigation Drawer"
          color="neutral"
          onClick={toggleSidebar}
          startIcon={
            <MenuIcon
              aria-label={SIDE_MENU_BTN_LABEL}
              title={SIDE_MENU_BTN_LABEL}
            />
          }
          sx={{ marginRight: 1 }}
          variant="text"
        />
      ) : null}
      <PageHeaderPortal />
    </>
  );

  const getRightToolbar = () => (
    <>
      <StyledButton
        aria-label="User settings"
        color="neutral"
        onClick={() => {
          setIsPopoverOpen((prev) => !prev);
        }}
        startIcon={
          <ElementWithBadge
            badgeColor="error"
            color={ColorGreen200}
            element="avatar"
            name={user?.displayName || user?.email || 'Claim Score'}
            ref={anchorEl}
            size="small"
          />
        }
        variant="text"
      />

      <PopoverMenu
        anchorEl={anchorEl.current}
        heading={
          <StyledAvatarContainer>
            <ElementWithBadge
              color={ColorGreen200}
              element="avatar"
              name={user?.displayName || user?.email || 'Claim Score'}
              size="large"
            />
            <Typography variant="h5">
              {user?.displayName || user?.email}
            </Typography>
          </StyledAvatarContainer>
        }
        isOpen={isPopoverOpen}
        menuItems={[
          {
            onClick: () => {
              navigate('/security');
              setSearchParams();
            },
            title: 'Security',
          },
          {
            onClick: () => {
              logout();
              setSearchParams();
            },
            title: 'Sign out',
            icon: (
              <Typography color="error" variant="body">
                <LogoutIcon />
              </Typography>
            ),
          },
        ]}
        onClose={() => {
          setIsPopoverOpen(false);
        }}
      />
    </>
  );

  return (
    <StyledNavbar
      hasSidebar={hasSideBar}
      leftToolbar={getLeftToolbar()}
      logoVariant="none"
      rightToolbar={getRightToolbar()}
    />
  );
}
