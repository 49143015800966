import { styled } from '@mui/material';
import React, { ReactNode } from 'react';
import { ClaimscoreLogoLarge } from '../Icons/ClaimscoreLogoLarge';
import { ClaimscoreLogoSmall } from '../Icons/ClaimscoreLogoSmall';
import {
  LogoWrapper,
  RightToolbarContainer,
  TopNavbarToolbar,
  TopNavbarWrapper,
} from './TopNavbar.styles';

export interface TopNavBarProps {
  readonly logoVariant: 'none' | 'small' | 'large';
  readonly leftToolbar?: ReactNode;
  readonly rightToolbar?: ReactNode;
  readonly thin?: boolean;
}

const shouldForwardProp = (prop: string) => prop !== 'leftToolbar';

const StyledClaimscoreLogoLarge = styled(ClaimscoreLogoLarge, {
  shouldForwardProp,
})<{
  leftToolbar?: boolean;
}>((props) => ({ marginLeft: props.leftToolbar ? props.theme.spacing(1) : 0 }));

const StyledClaimscoreLogoSmall = styled(ClaimscoreLogoSmall, {
  shouldForwardProp,
})<{
  leftToolbar?: boolean;
}>((props) => ({ marginLeft: props.leftToolbar ? props.theme.spacing(1) : 0 }));

export function TopNavbar({
  logoVariant = 'none',
  leftToolbar = null,
  rightToolbar = null,
  thin = true,
  ...rest
}: TopNavBarProps) {
  return (
    <TopNavbarWrapper {...rest}>
      <TopNavbarToolbar thin={thin}>
        <RightToolbarContainer>
          {leftToolbar}
          {logoVariant !== 'none' && (
            <LogoWrapper>
              {logoVariant === 'small' ? (
                <StyledClaimscoreLogoSmall leftToolbar={Boolean(leftToolbar)} />
              ) : (
                <StyledClaimscoreLogoLarge leftToolbar={Boolean(leftToolbar)} />
              )}
            </LogoWrapper>
          )}
        </RightToolbarContainer>
        {rightToolbar}
      </TopNavbarToolbar>
    </TopNavbarWrapper>
  );
}
