import { styled } from '@mui/material';
import React, { ReactNode } from 'react';
import { Button } from '../Button';
import { Stack } from '../Stack';
import { Typography } from '../Typography';

const StyledButton = styled(Button)`
  height: 150px;
  width: 260px;
  padding: ${({ theme }) => theme.spacing(2)};
  border: 1px solid ${({ theme }) => theme.palette.neutral[200]};
`;
const StyledStack = styled(Stack)`
  align-items: flex-start;
  text-align: left;
  gap: ${({ theme }) => theme.spacing(1)};
`;
const GapStack = styled(Stack)`
  gap: ${({ theme }) => theme.spacing(3)};
`;
interface DescriptiveButtonProps {
  readonly title: string;
  readonly description: string;
  readonly onClick: () => void;
  readonly icon: ReactNode;
}

export function DescriptiveButton({
  title,
  onClick,
  description,
  icon,
}: DescriptiveButtonProps) {
  return (
    <StyledButton onClick={onClick} variant="outlined">
      <GapStack>
        {icon}
        <StyledStack>
          <Typography color="text.primary" variant="subtitle">
            {title}
          </Typography>
          <Typography color="text.secondary" variant="body">
            {description}
          </Typography>
        </StyledStack>
      </GapStack>
    </StyledButton>
  );
}
