import { styled } from '@mui/material';
import React, { useMemo } from 'react';
import {
  currencyFormatterInteger,
  numberFormatter,
} from '../../utils/number-formatter';
import { Stack } from '../Stack';
import { Typography } from '../Typography';
import { SummaryCardField } from './SummaryCardField';

const StyledTypography = styled(Typography)`
  flex: 1;
  text-align: end;
`;

export const StyledStackContainer = styled(Stack)`
  border-radius: ${({ theme }) => theme.borderRadii.inner};
  border: 1px solid;
  border-bottom: none;
  border-color: ${({ theme }) => theme.palette.neutral[200]};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  width: 100%;
  flex-direction: column;
`;

export const StyledItemStack = styled(Stack)`
  justify-content: space-between;
  width: 100%;
`;
export const StyledValueStack = styled(Stack)`
  justify-content: space-between;
  flex: 1;
  flex-direction: row;
`;

interface SummaryCardGroupFieldsProps {
  readonly items: {
    key: string;
    value?: number | string;
    color?: string;
    percentage?: number;
    isBold?: boolean;
  }[];
  readonly headerLabel?: string;
  readonly headerContent?: React.ReactNode;
  readonly format?: 'number' | 'currency';
}

export function SummaryCardGroupFields({
  items,
  headerLabel,
  headerContent,
  format,
}: SummaryCardGroupFieldsProps) {
  const formatter = useMemo(() => {
    return format === 'number' ? numberFormatter : currencyFormatterInteger;
  }, [format]);

  return (
    <StyledStackContainer>
      {headerLabel && headerContent ? (
        <SummaryCardField label={headerLabel}>{headerContent}</SummaryCardField>
      ) : null}
      {items.map(({ key, value, color, percentage, isBold = false }) => (
        <SummaryCardField
          color={color}
          key={`stack-${key}${value}`}
          label={key}
        >
          <StyledValueStack>
            {value != null && (
              <StyledTypography variant={isBold ? 'bodyStrong' : 'body'}>
                {typeof value === 'string' ? value : formatter.format(value)}
              </StyledTypography>
            )}

            {percentage != null && (
              <StyledTypography color="text.secondary" variant="body">
                {percentage}%
              </StyledTypography>
            )}
          </StyledValueStack>
        </SummaryCardField>
      ))}
    </StyledStackContainer>
  );
}
