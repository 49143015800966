import { css } from '@mui/material';
import * as React from 'react';
import { theme } from '../../design-system/theme';
import { getSize } from '../../utils/get-size';
import { styled } from '../../utils/styled';
import { Avatar } from '../Avatar';
import { Badge } from '../Badge';
import { BadgeColor } from '../Badge/Badge';
import { Box } from '../Box';
import { Typography } from '../Typography';
import { getNameInitials, getPersistedColor } from './utils';

export type AvatarSize = 'small' | 'large';
export type ElementType = 'avatar' | React.ReactNode;

const RelativeWrapper = styled('div')(() => ({
  position: 'relative',
}));
const StyledBox = styled(Box)<{ size: AvatarSize }>(({ size }) => ({
  ...getSize(sizes[size]),
}));
const AbsoluteWrapper = styled('div')<{ size: AvatarSize }>`
  position: absolute;
  ${({ size }) =>
    size === 'small'
      ? css`
          transform: translate(50%, -50%);
          top: 10%;
          right: 10%;
        `
      : css`
          transform: translate(50%, -50%);
          top: 15%;
          right: 15%;
        `}
`;

const sizes: Record<AvatarSize, number> = {
  small: 24,
  large: 48,
};

export interface ElementWithBadgeProps {
  readonly size: AvatarSize;
  readonly name: string;
  readonly color?: string;
  readonly counter?: number;
  readonly element: ElementType;
  readonly badgeColor?: BadgeColor;
  readonly 'data-testid'?: string;
}

export const ElementWithBadge = React.forwardRef(
  (
    {
      size,
      name,
      counter,
      color,
      element,
      badgeColor = 'error',
      'data-testid': dataTestId,
    }: ElementWithBadgeProps,
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => (
    <RelativeWrapper ref={ref}>
      {element === 'avatar' ? (
        <Avatar
          sx={{
            color: theme.palette.neutral[800],
            bgcolor: color || getPersistedColor(),
            ...getSize(sizes[size]),
          }}
        >
          <Typography
            variant={size === 'small' ? 'captionStrong' : 'bodyStrong'}
          >
            {getNameInitials(name)}
          </Typography>
        </Avatar>
      ) : (
        <StyledBox size={size}>{element}</StyledBox>
      )}
      {counter ||
        (counter === 0 && (
          <AbsoluteWrapper size={size}>
            <Badge
              color={badgeColor}
              count={counter}
              data-testid={dataTestId}
              size="small"
            />
          </AbsoluteWrapper>
        ))}
    </RelativeWrapper>
  ),
);
