import { AccordionDetails, Typography, useTheme } from '@mui/material';
import React, { ReactNode, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Kind } from '../../types/kind';
import { Badge } from '../Badge';
import { Box } from '../Box';
import { ChevronDownIcon } from '../Icons/ChevronDownIcon';
import {
  AccordionInnerItems,
  AccordionLink,
  ItemWrapper,
  NavItemWrapper,
  StyledAccordion,
  StyledAccordionSummary,
  StyledBox,
} from './NavItem.styles';

type WithRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] };

type ItemProps = {
  readonly label?: string;
  readonly active?: boolean;
  readonly icon?: ReactNode;
  readonly counter?: number | null;
};

function Item({ icon, label, counter, active }: ItemProps) {
  const theme = useTheme();
  const color = active
    ? theme.palette.green[400]
    : theme.palette.text.secondaryInverted;

  return (
    <ItemWrapper color={color}>
      <StyledBox color={color}>{icon}</StyledBox>
      {label ? <Typography variant="bodyStrong">{label}</Typography> : null}
      {counter ? (
        <Box>
          <Badge color="error" count={counter} size="large" />
        </Box>
      ) : null}
    </ItemWrapper>
  );
}

type NavItemProps = {
  readonly kind: Kind;
  readonly label?: string;
  readonly to?: string;
  readonly counter?: number;
  readonly icon?: ReactNode;
  readonly subItems?: Omit<
    WithRequired<NavItemProps & ItemProps, 'to'>,
    'subItems' | 'kind'
  >[];
  readonly onClick?: () => void;
};

export function NavItem({
  kind,
  label,
  to,
  icon,
  subItems,
  counter,
  onClick,
}: NavItemProps) {
  const location = useLocation();
  const [accordionOpen, setAccordionOpen] = useState(
    subItems?.some((item) => location.pathname.includes(item.to)),
  );

  if (subItems?.length) {
    return (
      <StyledAccordion
        expanded={accordionOpen}
        kind={kind}
        onChange={() => setAccordionOpen((prev) => !prev)}
      >
        <StyledAccordionSummary
          expandIcon={<ChevronDownIcon aria-label="Expand" title="Expand" />}
          kind={kind}
        >
          <Item
            counter={
              accordionOpen
                ? null
                : subItems.reduce(
                    (acc, curr) => acc + (curr?.counter || 0),
                    0,
                  ) || null
            }
            icon={icon}
            label={label}
          />
        </StyledAccordionSummary>
        <AccordionDetails sx={{ mb: 0 }}>
          {subItems.map((item) => (
            <AccordionLink end key={item.label} to={item.to}>
              {({ isActive }) => (
                <AccordionInnerItems kind={kind}>
                  <Item
                    active={isActive}
                    counter={item.counter}
                    label={item.label}
                  />
                </AccordionInnerItems>
              )}
            </AccordionLink>
          ))}
        </AccordionDetails>
      </StyledAccordion>
    );
  }

  if (!to) {
    throw new Error('You must provide a either `subItems` or `to` props.');
  }

  return (
    <NavItemWrapper kind={kind}>
      <NavLink end onClick={onClick} to={to}>
        {({ isActive }) => (
          <Item active={isActive} counter={counter} icon={icon} label={label} />
        )}
      </NavLink>
    </NavItemWrapper>
  );
}
