import React, { ReactNode } from 'react';
import { styled } from '../../utils/styled';
import { Box } from '../Box';

export type EntityTextWithIconProps = {
  readonly text?: string;
  readonly icon?: ReactNode;
  readonly color?: string;
};

const StyledBox = styled(Box)<{ color?: string }>`
  display: flex;
  align-items: center;
  font-weight: bold;
  gap: ${({ theme }) => theme.spacing(1)};
  color: ${({ color }) => color || 'inherit'};
`;

export function EntityTextWithIcon({
  text = '',
  icon = undefined,
  color,
}: EntityTextWithIconProps) {
  return (
    <StyledBox color={color}>
      {icon}
      {text}
    </StyledBox>
  );
}
